const data = {
  name: 'Two Cols Text',
  variations: [
    {
      title: 'Default',
      props: {
        data: {
          items: [
            {
              title: {
                text: "What's wrong with traditional finance?",
              },
              dark_background: true,
              column_item_content: {
                html:
                  '<p><strong>It&#39;s hard<br /></strong>Not only is it a slow and painful process, it’s really hard to shop around and compare rates.</p><p></p><p><strong>Everything is hidden<br /></strong>Interest rates and fees are not shared with the customer. Brokers favour lenders that pay the highest commissions, not those that have the best rates.</p><p></p><p><strong>It&#39;s not personalised<br /></strong>Almost every other finance company uses generic starting rates not specific to you - these rates then change later in the process.</p>',
                text:
                  "It's hard\nNot only is it a slow and painful process, it’s really hard to shop around and compare rates.  Everything is hidden\nInterest rates and fees are not shared with the customer. Brokers favour lenders that pay the highest commissions, not those that have the best rates.  It's not personalised\nAlmost every other finance company uses generic starting rates not specific to you - these rates then change later in the process.",
              },
            },
            {
              title: {
                text: "What's right with Driva?",
              },
              dark_background: false,
              column_item_content: {
                html:
                  '<p><strong>It&#39;s easy<br /></strong>We take the hard work out of the loan application process from start to finish.</p><p></p><p><strong>Nothing is hidden<br /></strong>We&#39;re 100% transparent, so there are no hidden surprises.</p><p></p><p><strong>Personalised<br /></strong>We provide you with personalised rates based on your profile. This means no last minute rate changes just before you sign the contract.</p>',
                text:
                  "It's easy\nWe take the hard work out of the loan application process from start to finish.  Nothing is hidden\nWe're 100% transparent, so there are no hidden surprises.  Personalised\nWe provide you with personalised rates based on your profile. This means no last minute rate changes just before you sign the contract.",
              },
            },
          ],
        },
      },
    },
  ],
};

export default data;
