const data = {
  name: 'FAQ Accordion',
  variations: [
    {
      title: 'Default',
      props: {
        data: {
          primary: {
            title: {
              html: '<h3>Have a question?</h3>',
              text: 'Have a question?',
            },
          },
          items: [
            {
              question: {
                html: '<h5>What is Driva?</h5>',
                text: 'What is Driva?',
              },
              answer: {
                html: '<p>Driva’s mission is to help you navigate existing finance lenders in the market.<br /><br />There’s already so many finance lenders out there that we thought adding another name to the list wouldn’t help. It also means we don’t have any incentive to steer you down the wrong path.<br /><br />We take the hard work out of the online car loan application process from start to finish providing a seamless experience. We&#39;re 100% transparent, so there are no hidden surprises and provide you with personalised rates based on your profile. This means no last minute rate changes just before you sign the contract.</p>',
                text: "Driva’s mission is to help you navigate existing finance lenders in the market.\n\nThere’s already so many finance lenders out there that we thought adding another name to the list wouldn’t help. It also means we don’t have any incentive to steer you down the wrong path.\n\nWe take the hard work out of the online car loan application process from start to finish providing a seamless experience. We're 100% transparent, so there are no hidden surprises and provide you with personalised rates based on your profile. This means no last minute rate changes just before you sign the contract.",
              },
            },
            {
              question: {
                html: '<h5>Which lenders do you work with?</h5>',
                text: 'Which lenders do you work with?',
              },
              answer: {
                html: '<p>We work with over 20 different car loan lenders to give you confidence that the options you’re seeing are a good fit for the vehicle you’re thinking about and your personal situation.<br /><br />Not all car loan lenders were born equal, which means some have slower response times, bigger fees, worse support functions and higher rates than others. But we’ve hand-picked those that we work with to support our vision of a seamless customer experience, an effortless online finance application process and the lowest rates.</p>',
                text: 'We work with over 20 different car loan lenders to give you confidence that the options you’re seeing are a good fit for the vehicle you’re thinking about and your personal situation.\n\nNot all car loan lenders were born equal, which means some have slower response times, bigger fees, worse support functions and higher rates than others. But we’ve hand-picked those that we work with to support our vision of a seamless customer experience, an effortless online finance application process and the lowest rates.',
              },
            },
            {
              question: {
                html: '<h5>How does a car loan work?</h5>',
                text: 'How does a car loan work?',
              },
              answer: {
                html: '<p>Car loans are a financial instrument that enable someone to buy a used/new car or similar type of vehicle (including a motorcycle or caravan). A lender or bank, after conducting responsible lending checks, will approve a customer for a car loan. This loan is either deposited to the customer’s bank account, given to them as a cheque, or wired directly to their dealer or seller. In return for this money, the borrower must pay back the lender (usually monthly repayments) the initial amount over several years (typically 5 years but this varies) with interest.</p>',
                text: 'Car loans are a financial instrument that enable someone to buy a used/new car or similar type of vehicle (including a motorcycle or caravan). A lender or bank, after conducting responsible lending checks, will approve a customer for a car loan. This loan is either deposited to the customer’s bank account, given to them as a cheque, or wired directly to their dealer or seller. In return for this money, the borrower must pay back the lender (usually monthly repayments) the initial amount over several years (typically 5 years but this varies) with interest.',
              },
            },
            {
              question: {
                html: '<h5>What’s the difference between variable vs fixed rate car loans?</h5>',
                text: 'What’s the difference between variable vs fixed rate car loans?',
              },
              answer: {
                html: '<p><span  class="text-underline">Fixed Rate Car Loan</span><br />Fixed rate car loans are loans where the interest rate charged on the loan amount is the same for the entire loan term. A fixed rate car loan will stay the same whether the Reserve Bank of Australia (RBA) cash rate goes up or down. The majority of new or used car loans are at a fixed rate.<br /><br />Fixed rate loans make your car loan more reliable in terms of repayments. Your repayments will stay the same throughout the entire car loan term, so there’s no surprises if the interest rate fluctuates.<br /><br /><span  class="text-underline">Variable Rate Car Loans<strong></strong></span><strong><br /></strong>A variable rate car loan is as the name suggests, it has a variable rate of interest, so your repayments may fluctuate if the market interest rates change. This means your interest rate may increase or decrease over the course of the loan term, with the chance of averaging a low rate throughout the car loan term compared to a fixed rate.<br />‍<br />However, a variable rate car loan also comes with increased repayment flexibility when compared to a fixed rate car loan. Variable rate car loans usually don&#39;t have an early exit fee. This might be better if you&#39;re planning to make extra repayments and pay the car loan back early.</p>',
                text: "Fixed Rate Car Loan\nFixed rate car loans are loans where the interest rate charged on the loan amount is the same for the entire loan term. A fixed rate car loan will stay the same whether the Reserve Bank of Australia (RBA) cash rate goes up or down. The majority of new or used car loans are at a fixed rate.\n\nFixed rate loans make your car loan more reliable in terms of repayments. Your repayments will stay the same throughout the entire car loan term, so there’s no surprises if the interest rate fluctuates.\n\nVariable Rate Car Loans\nA variable rate car loan is as the name suggests, it has a variable rate of interest, so your repayments may fluctuate if the market interest rates change. This means your interest rate may increase or decrease over the course of the loan term, with the chance of averaging a low rate throughout the car loan term compared to a fixed rate.\n‍\nHowever, a variable rate car loan also comes with increased repayment flexibility when compared to a fixed rate car loan. Variable rate car loans usually don't have an early exit fee. This might be better if you're planning to make extra repayments and pay the car loan back early.",
              },
            },
            {
              question: {
                html: '<h5>Can I pay out my loan early?</h5>',
                text: 'Can I pay out my loan early?',
              },
              answer: {
                html: '<p>You can always pay out your existing car loan, but some lenders may charge you what&#39;s called an early termination fee in addition to the outstanding loan amount to do this.<br /><br />Using Driva&#39;s online loan matching tool, you can find out which of your eligible lender options will penalise you for making an early repayment on your loan.<br /><br />While we consider monthly repayments (inclusive of all fees) as the most important factor to consider when assessing your Driva quotes, it is also important to consider early termination fees if you intend to pay out your loan early. In some cases, it may be worth choosing a lender with a slightly higher monthly repayment to avoid early termination fees and charges if you want the flexibility of reducing your interest and paying off your loan early.</p>',
                text: "You can always pay out your existing car loan, but some lenders may charge you what's called an early termination fee in addition to the outstanding loan amount to do this.\n\nUsing Driva's online loan matching tool, you can find out which of your eligible lender options will penalise you for making an early repayment on your loan.\n\nWhile we consider monthly repayments (inclusive of all fees) as the most important factor to consider when assessing your Driva quotes, it is also important to consider early termination fees if you intend to pay out your loan early. In some cases, it may be worth choosing a lender with a slightly higher monthly repayment to avoid early termination fees and charges if you want the flexibility of reducing your interest and paying off your loan early.",
              },
            },
            {
              question: {
                html: '<h5>How long does the process take?</h5>',
                text: 'How long does the process take?',
              },
              answer: {
                html: '<p>The entire process, from getting an online finance quote and approval to your chosen seller receiving funds in their bank account can be done as quickly as within 24 hours. That said, actual turnaround times will vary fairly significantly depending on your chosen lender.<br /><br />Our short and simple online application only takes 2 minutes to fill out, and you can see your loan options from within your Driva Dashboard instantly, you can get started and apply <a  href="http://dash.driva.com.au/">online here now.</a><br /><br />Once you’ve picked your lender and provided a few other details (like income, employment and expenses), we will work with your chosen lender to lodge your finance application. This is usually done within 3 business hours, but we might require some additional information or have a few extra questions for you before we can finalise your application and lodge it with your chosen lender.<br /><br />Approval can then take anywhere from a couple of hours to 48 hours. The Driva team is closely aligned with our lending partners to make this as fast a process as possible, and in some cases, we even have guaranteed turn around times in place so that you can be on the road in your new car in no time.</p>',
                text: 'The entire process, from getting an online finance quote and approval to your chosen seller receiving funds in their bank account can be done as quickly as within 24 hours. That said, actual turnaround times will vary fairly significantly depending on your chosen lender.\n\nOur short and simple online application only takes 2 minutes to fill out, and you can see your loan options from within your Driva Dashboard instantly, you can get started and apply online here now.\n\nOnce you’ve picked your lender and provided a few other details (like income, employment and expenses), we will work with your chosen lender to lodge your finance application. This is usually done within 3 business hours, but we might require some additional information or have a few extra questions for you before we can finalise your application and lodge it with your chosen lender.\n\nApproval can then take anywhere from a couple of hours to 48 hours. The Driva team is closely aligned with our lending partners to make this as fast a process as possible, and in some cases, we even have guaranteed turn around times in place so that you can be on the road in your new car in no time.',
              },
            },
            {
              question: {
                html: '<h5>What is a comparison rate?</h5>',
                text: 'What is a comparison rate?',
              },
              answer: {
                html: '<p>When shopping around for online car finance, you can use a comparison rate to help work out the true cost of a loan. This is because it accounts for all of the fees and charges a lender is paid for originating car loans.<br /><br />Driva clearly specifies the comparison rate of every quote we provide to you so you can quickly see which car loans will cost you less. Our tip is to rely on either the comparison rate or monthly repayment figure instead of the quoted annual percentage rate or interest rate, as these can be very misleading!<br /><br />For example, some lenders might lead with an exceptionally low interest rate to appeal to new customers looking for the best deal. But these same lenders often have high establishment, monthly or termination fees associated with their car loans which then lead to a significantly higher comparison rate.<br /><br />It&#39;s important to take caution when relying on online resources like a car loan calculator, which might not accurately account for all fees associated with a particular new or used car loan. This might lead to a rate comparison made on an interest rate, instead of a comparison rate, and overall a poorer outcome for the consumer.<br /><br />‍<a target="_blank" rel="noopener" href="https://www.driva.com.au/blog/car-loan-finance-terminology-explained">Learn: car finance terminology explained</a></p>',
                text: "When shopping around for online car finance, you can use a comparison rate to help work out the true cost of a loan. This is because it accounts for all of the fees and charges a lender is paid for originating car loans.\n\nDriva clearly specifies the comparison rate of every quote we provide to you so you can quickly see which car loans will cost you less. Our tip is to rely on either the comparison rate or monthly repayment figure instead of the quoted annual percentage rate or interest rate, as these can be very misleading!\n\nFor example, some lenders might lead with an exceptionally low interest rate to appeal to new customers looking for the best deal. But these same lenders often have high establishment, monthly or termination fees associated with their car loans which then lead to a significantly higher comparison rate.\n\nIt's important to take caution when relying on online resources like a car loan calculator, which might not accurately account for all fees associated with a particular new or used car loan. This might lead to a rate comparison made on an interest rate, instead of a comparison rate, and overall a poorer outcome for the consumer.\n\n‍Learn: car finance terminology explained",
              },
            },
            {
              question: {
                html: '<h5>What is a secured car loan?</h5>',
                text: 'What is a secured car loan?',
              },
              answer: {
                html: '<p>A secured car loan means that the car that is being purchased is used as collateral in the event of a loan default. It gives lenders additional comfort that they will be able to recoup the money lent, and as a result, generally will be accompanied by lower interest rates.<br /><br />Most secured car loans require a vehicle to be 12 years or under, which means if you&#39;re buying a car older than this you&#39;ll have to opt for an unsecured personal loan.</p>',
                text: "A secured car loan means that the car that is being purchased is used as collateral in the event of a loan default. It gives lenders additional comfort that they will be able to recoup the money lent, and as a result, generally will be accompanied by lower interest rates.\n\nMost secured car loans require a vehicle to be 12 years or under, which means if you're buying a car older than this you'll have to opt for an unsecured personal loan.",
              },
            },
            {
              question: {
                html: '<h5>How much can I borrow with a car loan?</h5>',
                text: 'How much can I borrow with a car loan?',
              },
              answer: {
                html: '<p>Loan amounts for any type of finance will vary from customer to customer, depending on their personal situation. Things like living expenses, income sources, living situation and past credit rating will all contribute to your maximum loan amount.<br /><br />Adjusting your loan term (e.g. from 5 years to 7 years) can also help with your maximum borrowing amount, and Driva&#39;s smart online platform and expert team will give you the advice you need to find out the maximum amount of finance you could be approved for.<br /><br />If you need assistance in determining how much to spend on a new vehicle, check out our resource, ‘<strong><a  href="https://www.driva.com.au/blog/how-much-to-spend-on-a-car-a-complete-guide">How Much To Spend On A Car - A Complete Guide</a>,</strong>’ for more information.</p>',
                text: "Loan amounts for any type of finance will vary from customer to customer, depending on their personal situation. Things like living expenses, income sources, living situation and past credit rating will all contribute to your maximum loan amount.\n\nAdjusting your loan term (e.g. from 5 years to 7 years) can also help with your maximum borrowing amount, and Driva's smart online platform and expert team will give you the advice you need to find out the maximum amount of finance you could be approved for.\n\nIf you need assistance in determining how much to spend on a new vehicle, check out our resource, ‘How Much To Spend On A Car - A Complete Guide,’ for more information.",
              },
            },
            {
              question: {
                html: '<h5>Do you do commercial car loans?</h5>',
                text: 'Do you do commercial car loans?',
              },
              answer: {
                html: '<p>Whether you&#39;re buying a new car through your business, or want to take out a personal loan for a used car, are self-employed or a contractor, Driva has a car loan solution to suit your needs.<br /><br />In fact in some cases, Driva&#39;s commercial car loan lenders are able to offer lower interest rates than if you were to acquire a car through a personal loan.<br /><br />The most popular vehicle finance option for commercial use offered by Driva is a <a  href="https://www.driva.com.au/blog/chattel-mortgage-vs-lease">chattel mortgage</a>. This is a car finance product where a financier lends the money to buy a car and the customer makes regular repayments. The business assumes ownership of the vehicle but the financier has a ‘mortgage’ over it until the loan is paid, including any balloon payment.<br /><strong>Learn: </strong><a  href="https://www.driva.com.au/blog/buying-a-vehicle-for-small-business"><strong></strong>Buying A Vehicle For Your Small Business - 3 Important Things To Consider</a></p>',
                text: "Whether you're buying a new car through your business, or want to take out a personal loan for a used car, are self-employed or a contractor, Driva has a car loan solution to suit your needs.\n\nIn fact in some cases, Driva's commercial car loan lenders are able to offer lower interest rates than if you were to acquire a car through a personal loan.\n\nThe most popular vehicle finance option for commercial use offered by Driva is a chattel mortgage. This is a car finance product where a financier lends the money to buy a car and the customer makes regular repayments. The business assumes ownership of the vehicle but the financier has a ‘mortgage’ over it until the loan is paid, including any balloon payment.\nLearn: Buying A Vehicle For Your Small Business - 3 Important Things To Consider",
              },
            },
          ],
        },
      },
    },
  ],
};

export default data;
