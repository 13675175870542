const data = {
  name: 'CTA',
  variations: [
    {
      title: 'Image Align Right',
      props: {
        data: {
          primary: {
            cta_link: {
              url: '/',
            },
            cta_text: {
              html: '<p>Find rate options</p>',
              text: 'Find rate options',
            },
            align_image_left: false,
            image: {
              url:
                'https://images.prismic.io/driva/423baeb3-2db7-48e2-850d-1cfacb163bc9_person-driving.jpeg?auto=compress%2Cformat&rect=0%2C0%2C790%2C361&w=2000&h=914',
              localFile: {
                childImageSharp: {
                  gatsbyImageData: {
                    layout: 'constrained',
                    backgroundColor: '#f8f8f8',
                    images: {
                      fallback: {
                        src:
                          '/static/aa0e16920b4501646eb860a06915aaa8/f7f6d/423baeb3-2db7-48e2-850d-1cfacb163bc9_person-driving.jpg',
                        srcSet:
                          '/static/aa0e16920b4501646eb860a06915aaa8/27855/423baeb3-2db7-48e2-850d-1cfacb163bc9_person-driving.jpg 500w,\n/static/aa0e16920b4501646eb860a06915aaa8/53af1/423baeb3-2db7-48e2-850d-1cfacb163bc9_person-driving.jpg 1000w,\n/static/aa0e16920b4501646eb860a06915aaa8/f7f6d/423baeb3-2db7-48e2-850d-1cfacb163bc9_person-driving.jpg 2000w',
                        sizes: '(min-width: 2000px) 2000px, 100vw',
                      },
                      sources: [
                        {
                          srcSet:
                            '/static/aa0e16920b4501646eb860a06915aaa8/3852f/423baeb3-2db7-48e2-850d-1cfacb163bc9_person-driving.webp 500w,\n/static/aa0e16920b4501646eb860a06915aaa8/0bc49/423baeb3-2db7-48e2-850d-1cfacb163bc9_person-driving.webp 1000w,\n/static/aa0e16920b4501646eb860a06915aaa8/73a0e/423baeb3-2db7-48e2-850d-1cfacb163bc9_person-driving.webp 2000w',
                          type: 'image/webp',
                          sizes: '(min-width: 2000px) 2000px, 100vw',
                        },
                      ],
                    },
                    width: 2000,
                    height: 914.0000000000001,
                  },
                },
              },
            },
            title: {
              html: '<h1>Start Driva Now</h1>',
              text: 'Start Driva Now',
            },
          },
        },
      },
    },
    {
      title: 'Image Align Left',
      props: {
        data: {
          primary: {
            cta_link: {
              url: '/',
            },
            cta_text: {
              html: '<p>Find rate options</p>',
              text: 'Find rate options',
            },
            align_image_left: true,
            image: {
              url:
                'https://images.prismic.io/driva/423baeb3-2db7-48e2-850d-1cfacb163bc9_person-driving.jpeg?auto=compress%2Cformat&rect=0%2C0%2C790%2C361&w=2000&h=914',
              localFile: {
                childImageSharp: {
                  gatsbyImageData: {
                    layout: 'constrained',
                    backgroundColor: '#f8f8f8',
                    images: {
                      fallback: {
                        src:
                          '/static/aa0e16920b4501646eb860a06915aaa8/f7f6d/423baeb3-2db7-48e2-850d-1cfacb163bc9_person-driving.jpg',
                        srcSet:
                          '/static/aa0e16920b4501646eb860a06915aaa8/27855/423baeb3-2db7-48e2-850d-1cfacb163bc9_person-driving.jpg 500w,\n/static/aa0e16920b4501646eb860a06915aaa8/53af1/423baeb3-2db7-48e2-850d-1cfacb163bc9_person-driving.jpg 1000w,\n/static/aa0e16920b4501646eb860a06915aaa8/f7f6d/423baeb3-2db7-48e2-850d-1cfacb163bc9_person-driving.jpg 2000w',
                        sizes: '(min-width: 2000px) 2000px, 100vw',
                      },
                      sources: [
                        {
                          srcSet:
                            '/static/aa0e16920b4501646eb860a06915aaa8/3852f/423baeb3-2db7-48e2-850d-1cfacb163bc9_person-driving.webp 500w,\n/static/aa0e16920b4501646eb860a06915aaa8/0bc49/423baeb3-2db7-48e2-850d-1cfacb163bc9_person-driving.webp 1000w,\n/static/aa0e16920b4501646eb860a06915aaa8/73a0e/423baeb3-2db7-48e2-850d-1cfacb163bc9_person-driving.webp 2000w',
                          type: 'image/webp',
                          sizes: '(min-width: 2000px) 2000px, 100vw',
                        },
                      ],
                    },
                    width: 2000,
                    height: 914.0000000000001,
                  },
                },
              },
            },
            title: {
              html: '<h1>Start Driva Now</h1>',
              text: 'Start Driva Now',
            },
          },
        },
      },
    },
  ],
};

export default data;
